<template>
  <div v-if="!loading">
    <Card :title="title" :disabled="!editMode">
      <template #close>
        <a @click.prevent="goBack">
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
      </template>
      <template #actions>
        <v-btn
          v-permission="{
            permissions: ['message-templates-update'],
          }"
          @click="editMode = !editMode"
        >
          <span v-if="editMode">Görüntüle</span>
          <span v-if="!editMode">Düzenle</span>
        </v-btn>
      </template>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-card flat class="pa-4">
          <v-container>
            <h2 class="mb-4">Bilgiler</h2>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  hide-details="auto"
                  v-model="currentMessageTemplate.subject"
                  label="Konu"
                  :rules="[(v) => !!v || 'Konu alanı boş bırakılamaz']"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <SMMTCategorySelectboxVue
                  :required="true"
                  :value="currentMessageTemplate.category"
                  @update:value="
                    (category) => (currentMessageTemplate.category = category)
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="currentMessageTemplate.content"
                  hide-details="auto"
                  label="İçerik"
                  :rules="[(v) => !!v || 'İçerik alanı boş bırakılamaz']"
                  required
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-content-end">
                  <v-btn class="mr-3" color="error" @click="resetTemplate">
                    İptal
                  </v-btn>
                  <v-btn
                    class="mr-3"
                    color="primary"
                    :disabled="!isFormValid"
                    @click="save"
                  >
                    Kaydet
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    @click="saveAndClose"
                  >
                    Kaydet ve Kapat
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-form>
    </Card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  UPDATE_MESSAGE_TEMPLATE,
  CREATE_MESSAGE_TEMPLATE,
  GET_MESSAGE_TEMPLATE,
} from "@/core/services/store/definitions/message-templates.module";
import Card from "@/view/content/components/Card";
import SMMTCategorySelectboxVue from "@/view/content/siliconmade/SM-MT-Category-Selectbox.vue";

export default {
  components: { Card, SMMTCategorySelectboxVue },
  data() {
    return {
      currentMessageTemplate: {},
      editMode: false,
      loading: !!this.$route.params.id,
      isFormValid: true,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getMessageTemplate(this.$route.params.id)
        .then((data) => {
          this.currentMessageTemplate = Object.assign({}, data);
        })
        .finally(() => {
          this.loading = false;
        });
    }

    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    ...mapActions([
      UPDATE_MESSAGE_TEMPLATE,
      CREATE_MESSAGE_TEMPLATE,
      GET_MESSAGE_TEMPLATE,
    ]),
    async save(replaceRoute = true) {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.isFormValid = false;
        return;
      }
      if (this.$route.params.id) {
        try {
          const messageTemplate = JSON.parse(
            JSON.stringify(this.currentMessageTemplate)
          );
          const data = {
            ...messageTemplate,
            id: this.$route.params.id,
          };
          await this.updateMessageTemplate(data);
          this.$toast.success("Mesaj şablonu güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const messageTemplate = await this.createMessageTemplate(
            this.currentMessageTemplate
          );
          if (replaceRoute) {
            this.$router.push({
              name: "def-message-templates-edit",
              params: {
                id: messageTemplate.id,
              },
              query: { editable: true },
            });
          }
          this.$toast.success("Mesaj şablonu yaratıldı", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
    },
    async saveAndClose() {
      await this.save(false);
      if (this.isFormValid && !this.stopExecution)
        this.$router.push({ name: "def-message-templates-list" });
      this.stopExecution = false;
    },
    resetTemplate() {
      if (this.$route.params.id) {
        this.currentMessageTemplate = Object.assign({}, this.messageTemplate);
      } else {
        this.currentMessageTemplate = {};
      }
      this.$router.push({ name: "def-message-templates-list" });
    },
    goBack() {
      if (
        this.$router.history.current.path.includes(
          "definitions/message-templates/edit"
        )
      ) {
        this.$router.push({ name: "def-message-templates-list" });
      } else {
        this.$router.go(-1);
      }
    },
  },
  computed: {
    ...mapGetters(["messageTemplate"]),
    title() {
      if (this.$route.params.id) {
        if (this.editMode) return "Mesaj Şablonu Düzenle";
        return "Mesaj Şablonu Detay";
      }
      return "Mesaj Şablonu Yaratma";
    },
  },
};
</script>
