<template>
  <v-select
    @input="selectCategory"
    :items="categories"
    item-value="value"
    item-text="translate"
    hide-details="auto"
    v-model="selectedCategory"
    :loading="loading"
    label="Kategori"
    :required="required"
    :rules="rules"
  >
  </v-select>
</template>

<script>
import { defineComponent } from "vue";

import AxiosInstance from "@/core/services/axios.service";

export default defineComponent({
  name: "SM-MT-Category-Selectbox",
  props: {
    value: Number,
    required: Boolean,
  },
  data() {
    return {
      selectedCategory: this.value,
      loading: true,
      categories: [],
      rules: [(v) => !!v || "Kategori seçimi zorunludur."],
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    selectCategory() {
      this.$emit("update:value", this.selectedCategory);
    },
    getCategories() {
      AxiosInstance.get("enums", {
        params: {
          enum: "message_template_category_enum",
        },
      })
        .then((response) => {
          this.categories = response.data;
        })
        .catch(() => {
          this.$toast.error("Kategoriler alınamadı.", {
            position: "bottom-right",
            duration: 2000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>
